import KkBase from './KkBase';

class KkTabBodyTop extends KkBase {
    // props
    //  topComponent
    //  isPaired
    //  isHearterOn
    //  hearterStatusText
    //  isBaseMeasuring
    //  isMeasuring
    //  hearterElapsed
    //  baseId
    //  baseIdText

    actionPairing = () => {
      this.props.topComponent.actionPairing()
    }

    actionHearterOn = () => {
      this.props.topComponent.actionHearterOn()
    }
    actionMeasurement = () => {
      this.props.topComponent.actionMeasurement()
    }

    render () {
      return (
        <div className="KkTabBodyTop">
          <div className="selectedBaseTitle">測定に使用するベース値</div>
          <div className="selectedBase">{/*this.props.baseId*/this.props.baseIdText}</div>
          <div className="selectedBaseTitle">
            ヒーターの状態(
            {Math.floor(this.props.hearterElapsed / 60) > 0 ? Math.floor(this.props.hearterElapsed / 60)+"m " : ""} 
            {this.props.hearterElapsed % 60}s)
          </div>
          <div className="selectedBase">{this.props.hearterStatusText}</div>

          <div className={this.props.isPaired === "true" ? "buttonComDisable" : "buttonCom"} onClick={() => {
            if (this.props.isPaired !== "true") {
              this.actionPairing()
            }
          }}><div className="buttonComText">ペアリング</div></div>

          <div className={this.props.isPaired === "true" ? "buttonCom" : "buttonComDisable"} onClick={() => {
            if (this.props.isPaired === "true") {
              this.actionHearterOn()
            }
          }}><div className="buttonComText">ヒーターON</div></div>

          <div className={
            (this.props.isPaired === "true")
            && (this.props.isHearterOn === "true")
            && (this.props.hearterStatusText === "ON")
            && (this.props.isBaseMeasuring === "false")
            && (this.props.isMeasuring === "false")
            && (this.props.baseId !== null)
            ? "buttonCom" : "buttonComDisable"
          }
          onClick={() => {
            if ((this.props.isPaired === "true")
              && (this.props.isHearterOn === "true")
              && (this.props.hearterStatusText === "ON")
              && (this.props.isBaseMeasuring === "false")
              && (this.props.isMeasuring === "false")
              && (this.props.baseId !== null)
            ) {
              this.actionMeasurement()
          }}}><div className="buttonComText">測定</div></div>

        </div>
      )
    }
}

export default KkTabBodyTop;
