import KkBase from './KkBase';

class KkTabBar extends KkBase {
    // props
    //  topComponent
    //  curTab
    //  isPaired
    //  isHearterOn
    //  hearterStatusText
    //  isBaseMeasuring
    //  isMeasuring

    setTab = (nameTab) => {
      console.log("in KkTabBar setTab()")
      this.props.topComponent.setTab(nameTab)
      //this.props.topComponent.setState({curTab: nameTab})
    }

    render () {
      return (
        <table className="areaTabBar">
          <tbody>
          <tr>
            <td className={
              this.props.curTab === "measurementTab"
//              ? "buttonTabSelected" : "buttonTab"
              ? "buttonTabSelected" :
                (
                  (
                    (this.props.isBaseMeasuring === "false")
                    && (this.props.topComponent.state.baseMeasurementId === null)
  	          )
                  ? "buttonTab" : "buttonTabDisable"
                )
            } onClick={() => {
              this.setTab("measurementTab")
	    }}>測定</td>

            <td className={
              this.props.curTab === "resultlistTab"
//              ? "buttonTabSelected" : "buttonTab"
              ? "buttonTabSelected" :
                (
                  (this.props.isBaseMeasuring === "false")
                  && (this.props.isMeasuring === "false")
                  && (this.props.topComponent.state.baseMeasurementId === null)
                  && (this.props.topComponent.state.measurementId === null)
                )
                ? "buttonTab" : "buttonTabDisable"
            } onClick={() => {
              if (
                (this.props.isBaseMeasuring === "false")
                && (this.props.isMeasuring === "false")
                && (this.props.topComponent.state.baseMeasurementId === null)
                && (this.props.topComponent.state.measurementId === null)
              ) {
                this.setTab("resultlistTab")
              }
	    }}>結果履歴</td>

            <td className={
              this.props.curTab === "baseselectTab"
              ? "buttonTabSelected" :
                (
                  (this.props.isBaseMeasuring === "false")
                  && (this.props.isMeasuring === "false")
                  && (this.props.topComponent.state.baseMeasurementId === null)
                  && (this.props.topComponent.state.measurementId === null)
                )
                ? "buttonTab" : "buttonTabDisable"
            } onClick={() => {
              if (
                (this.props.isBaseMeasuring === "false")
                && (this.props.isMeasuring === "false")
                && (this.props.topComponent.state.baseMeasurementId === null)
                && (this.props.topComponent.state.measurementId === null)
              ) {
                this.setTab("baseselectTab")
              }
	    }}>ベース選択</td>

            <td className={
              this.props.curTab === "basemeasurementTab"
              ? "buttonTabSelected" :
                (
                  (
                    (this.props.isPaired === "true")
                    && (this.props.isHearterOn === "true")
                    && (this.props.hearterStatusText === "ON")
                    && (this.props.isMeasuring === "false")
                    && (this.props.topComponent.state.measurementId === null)
  	          )
                  ? "buttonTab" : "buttonTabDisable"
                )
            }
            onClick={() => {
              if ((this.props.isPaired === "true")
                && (this.props.isHearterOn === "true")
                && (this.props.hearterStatusText === "ON")
                && (this.props.isMeasuring === "false")
                && (this.props.topComponent.state.measurementId === null)
	      ) {
                this.setTab("basemeasurementTab")
            }
            }}>ベース測定</td>
          </tr>
          </tbody>
        </table>
      )
    }
}

export default KkTabBar;
