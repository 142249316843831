import KkBase from './KkBase';
import KkRecordResultInput from './KkRecordResultInput';

class KkTabBodyMeasurement extends KkBase {

    actionEnteredComment = async (comment, tag, index) => {
      console.log(this.props.measurementId)
      /*var result = */await this.props.topComponent.addTag(comment, tag, index, this.props.measurementId, true)
      this.props.topComponent.setState({measurementId: null})
    }

    render () {
      return (
        <div className="KkTabBodyMeasurement">
          <div className="areaGraph">{
            this.props.topComponent.state.measurementCount + "/" + this.props.topComponent.state.measurementCountMax
          }</div>
          <KkRecordResultInput
            topComponent={this.props.topComponent}
            buttonEnable={
              (
                (this.props.topComponent.state.measurementId !== null)
                && (this.props.topComponent.state.isMeasuring === "false")
              )
              ? "true" : "false"
            }
            actionEnteredComment={this.actionEnteredComment}
          />
        </div>
      )
    }
}

export default KkTabBodyMeasurement;
