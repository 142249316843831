import KkBase from './KkBase';

class KkRecordResultInput extends KkBase {
    // props
    //  topComponent
    //  actionEnteredComment(comment, tag, index)

    state = {
      recordResultComment: "",
      recordResultTag: "2",
      recordResultIndex: "3",
    }

    render () {
      const defineViewItem = {
        recordResultTag: [
          { key: 0, value: "0", text: "無臭", },
          { key: 1, value: "1", text: "いい匂い", },
          { key: 2, value: "2", text: "やや悪臭(気にならない)", },
          { key: 3, value: "3", text: "悪臭(我慢できる)", },
          { key: 4, value: "4", text: "悪臭(我慢できない)", },
          { key: 5, value: "5", text: "なんとも言えない", },
        ],
        recordResultIndex: [
          { key: 0, value: "0", text: "臭気強度0", },
          { key: 1, value: "1", text: "臭気強度1", },
          { key: 2, value: "2", text: "臭気強度2", },
          { key: 3, value: "3", text: "臭気強度3", },
          { key: 4, value: "4", text: "臭気強度4", },
          { key: 5, value: "5", text: "臭気強度5", },
        ],
      }

      return (
        <div className="KkRecordResultInput">
          <textarea className="inputText" rows="3" name="recordResultComment" onChange={this.onChange}></textarea>
          <br/>
          <select className="selectText" name="recordResultTag" onChange={this.onChange} value={this.state.recordResultTag}>
          {defineViewItem.recordResultTag.map((selectitem) => (
            <option key={selectitem.key} value={selectitem.value}>{selectitem.text}</option>
	  ))}
          </select>
          <select className="selectText" name="recordResultIndex" onChange={this.onChange} value={this.state.recordResultIndex}>
          {defineViewItem.recordResultIndex.map((selectitem) => (
            <option key={selectitem.key} value={selectitem.value}>{selectitem.text}</option>
	  ))}
          </select>
          <br/>
          <div className={this.props.buttonEnable === "true" ? "buttonCom" : "buttonComDisable"} onClick={() => {
            if (this.props.buttonEnable === "true") {
              this.props.actionEnteredComment(
                this.state.recordResultComment,
                defineViewItem.recordResultTag[parseInt(this.state.recordResultTag,10)].text,
                defineViewItem.recordResultIndex[parseInt(this.state.recordResultIndex,10)].text
              )
            }
          }}><div className="buttonComText">入力内容決定</div></div>
        </div>
      )
    }
}

export default KkRecordResultInput;
