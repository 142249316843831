import KkBase from './KkBase';
import KkRecordResultInput from './KkRecordResultInput';

class KkTabBodyBaseMeasurement extends KkBase {
    // props
    //  topComponent
    //  isPaired
    //  isHearterOn
    //  hearterStatusText
    //  isBaseMeasuring
    //  isMeasuring

    actionBaseMeasurement = () => {
      console.log("in KkTabBodyBaseMeasurement actionBaseMeasurement()")
      this.props.topComponent.actionBaseMeasurement()
    }

    actionEnteredComment = async (comment, tag, index) => {
      await this.props.topComponent.addTag(comment, tag, index, this.props.baseMeasurementId, false)
      this.props.topComponent.setState({baseMeasurementId: null})
      this.props.topComponent.setTab("baseselectTab")
      //this.props.topComponent.setTab("measurementTab")
    // }
    }

    render () {
      return (
        <div className="KkTabBodyBaseMeasurement">
          <div className="selectedBaseTitle">ヒーターの状態(
            {Math.floor(this.props.hearterElapsed / 60) > 0 ? (this.props.hearterElapsed / 60, 10)+"m " : ""}
            {this.props.hearterElapsed % 60}s
          )</div>

          <div className="selectedBase">{this.props.hearterStatusText}</div>

          <div className={
            (this.props.isPaired === "true")
            && (this.props.isHearterOn === "true")
            && (this.props.hearterStatusText === "ON")
            && (this.props.isBaseMeasuring === "false")
            && (this.props.isMeasuring === "false")
            && (this.props.baseMeasurementId === null)
            ? "buttonCom" : "buttonComDisable"
          }
          onClick={() => {
            if ((this.props.isPaired === "true")
              && (this.props.isHearterOn === "true")
              && (this.props.hearterStatusText === "ON")
              && (this.props.isBaseMeasuring === "false")
              && (this.props.isMeasuring === "false")
              && (this.props.baseMeasurementId === null)
            ) {
              this.actionBaseMeasurement()
          }}}><div className="buttonComText">ベース測定</div></div>

          <div className="areaGraph">{
            this.props.topComponent.state.measurementCount + "/" + this.props.topComponent.state.measurementCountMax
          }</div>
          <KkRecordResultInput
            topComponent={this.props.topComponent}
            buttonEnable={
              (
                (this.props.topComponent.state.baseMeasurementId !== null)
                && (this.props.topComponent.state.isBaseMeasuring === "false")
              )
              ? "true" : "false"
            }
            actionEnteredComment={this.actionEnteredComment}
          />
        </div>
      )
    }
}

export default KkTabBodyBaseMeasurement;
