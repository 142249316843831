import React, { Component } from 'react';


class KkBase extends Component {
  ////////////////////////////////////////
  // onChange()
  onChange = e => {
    // console.log("in KkBase onChange()")
    // console.log("onChange name:", e.target.name, "  value:", e.target.value)
    this.setState({ [e.target.name] : e.target.value })
  }

  ////////////////////////////////////////
  // nullIsEmptyStr()
  nullIsEmptyStr = (obj) => {
//    if (obj === null) {
    if (obj == null) {
      return "";
    }
    return obj;
  }

  render() {
    return (
      <div>
        class KkBase instance  render()
      </div>
    );
  }
}

export default KkBase;
