const axios = require('axios');

export default class KkRest {
    API_BASE_URL = 'https://api.pt01.ymh-m.kunkunx.konicaminolta.jp/prot';

    post(api, params) {
        const url = `${this.API_BASE_URL}${api}`
        return new Promise((resolve) => {
            axios.post(url, params, {headers: {'Content-type': 'application/json'}})
            .then(res => resolve(res.data))
        })
    }
}

