import KkBase from './KkBase'; 

export default class KkTabBodyBaseSelect extends KkBase  {
	
//	async componentWillMount() {
//		await this.props.topComponent.loadBaseData()
//	}
	onClick = (uuid, text) => {
		var result = window.confirm("ベースを" + text + " に変更しますか?", "ベース選択")
		if (result) {
			this.props.topComponent.setState({ baseId: uuid })
			this.props.topComponent.setState({ baseIdText: text })
		}
	}
	
	render() {
		const baseList = this.props.baseList
		return (
			<div className="resultList">
				<table>
					<thead>
						<tr>
							<th>臭気</th>
							<th>タグ</th>
							<th>コメント</th>
						</tr>
					</thead>
					<tbody>
						{baseList && baseList.sort(
							(a, b) => {
								if (a.date < b.date) {
									return 1
								}
								else if (a.date > b.date) {
									return -1
								}
								return 0
							}
						).map((result, index) => {
//						{baseList.map((result, index) => {
							return (
								<tr
								className={
									this.props.topComponent.state.baseId === result.uuid ? "selectedBaseItem" : ""
								}
								key={index} onClick={this.onClick.bind(
									this,
									result.uuid, 
									//result.date + " " + result.comment
									this.props.topComponent.getBaseId2Text(result)
								)}>
									<td>{this.nullIsEmptyStr(result.recordResultIndex)}</td>
									<td>{this.nullIsEmptyStr(result.recordResultTag)}</td>
									<td>{this.nullIsEmptyStr(result.comment)}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		)
	}
}
