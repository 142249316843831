import './App.css';
import './kkx_y.css';
import KkBase from './KkBase';
import KkTabBar from './KkTabBar';
import KkTabBodyTop from './KkTabBodyTop';
import KkTabBodyMeasurement from './KkTabBodyMeasurement';
import KkTabBodyBaseMeasurement from './KkTabBodyBaseMeasurement';
import KkTabBodyResultList from './KkTabBodyResultList';
import KkTabBodyBaseSelect from './KkTabBodyBaseSelect';

import KkModelBle from './KkModelBle';
import KkRest from './KkRest';

// Auth
import { Auth } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";

const uuid = require('uuid');

class App extends KkBase {

    modelBle = new KkModelBle(this)
    useRest = new KkRest()

    state = {
      user: null,

      userId: 'yokozuka-kento@t2lab.co.jp',
      curTab: "measurementTab",
      isPaired: "false",
      isHearterOn: "false",
      isBaseMeasuring: "false",
      isMeasuring: "false",
      baseId: null,
      baseIdText: "",
      baseMeasurementId: null,
      measurementId: null,
      baseList: null,
      resultList: null,

      isHearterOnStartTime: null, // Date.now()
      hearterOnTimer: null,  // setInterval()
      hearterStatusText: "",

      hearterElapsed: 0,

      measurementCountMax: this.modelBle.measurementCountMax,
      measurementCount: 0,
    }

    async componentWillMount() {
      Auth.currentAuthenticatedUser({bypassCache: false}).then(
        user => {
          this.setState({user: user})
          this.setState({userId: user.username})
          //console.log(user)
	}
      ).catch(
        err => console.log(err)
      )

/*
      const baseList = await this.find('/findbase')
      const resultList = await this.find('/findmeasure')
      this.setState({baseList: baseList})
      this.setState({resultList: resultList})
      if (baseList) {
        //console.log(baseList)
        const base = this.getNewBaseId(baseList)
        this.setState({baseId: base.uuid})
      }
*/
    }

    /**
     * 最新のベースIDを取得します
     * @param {Object} baseList    ベースのデータ
     * @returns {string}           最新のベースuuid
     */
    getNewBaseId(baseList) {
      const dates = baseList.map(base => { return new Date(base.date) })
      const options = {year: "numeric", month: "numeric", day: "numeric",
                       hour: "2-digit", minute: "2-digit", second: "2-digit"}
      const date = new Date(Math.max.apply(null, dates)).toLocaleString("ja-JP", options)
      const result = baseList.filter(base => { return base.date === date })
      // 無理やりだけど勘弁して
      this.setState({baseIdText: this.getBaseId2Text(result[0])})
      return result[0]
    }

    getBaseId2Text(item) {
      return item.date + " " + item.comment
    }

    setTab = async (nameTab) => {
      console.log("in App setTab()")
      if (nameTab === "resultlistTab") {
        await this.loadMeasureData()
      }
      else if (nameTab === "baseselectTab") {
        await this.loadBaseData()
      }

      this.setState({curTab: nameTab})
    }

    /** ペアリングを行います */
    actionPairing = async () => {
      await this.modelBle.pairing()
      if (this.modelBle.bluetoothDevice) this.setState({isPaired: "true"})
    }

    /** ヒーターONを行います */
    actionHearterOn = () => {
      this.modelBle.hearterOn()
      if (this.state.hearterOnTimer !== null) {
        clearInterval(this.state.hearterOnTimer)
      }
      this.setState({isHearterOnStartTime: Date.now()})
      //this.hearterStatusCheck()
      const timer = setInterval(this.hearterStatusCheck, 1000)
      this.setState({hearterOnTimer: timer})
      this.setState({isHearterOn: "true"})
    }

    /** デバイスステータスの確認をします */
    hearterStatusCheck = async () => {
      const millis = Date.now() - this.state.isHearterOnStartTime
      const secs = Math.floor(millis / 1000)
      this.setState({hearterElapsed: parseInt(secs, 10)})
      // 測定時はヒーター確認不可(BLEコマンドが重複してしまうため)
      if ((this.state.isBaseMeasuring === "false")
        && (this.state.isMeasuring === "false")
        && (
          ((secs % 10) === 0) || (secs === 1)
        )
      ) {
        // 10秒に1回、センサーのヒーターの状態を確認する。
        const status = await this.modelBle.getHearterStatus()
        if (status === 4) this.setState({hearterStatusText: 'OFF'})
        if (status === 5) this.setState({hearterStatusText: 'ON'})
        
      }
    }

    loadBaseData = async () => {
      console.log("in App loadBaseData()")
      const results = await this.find('/findbase')
      this.setState({baseList: results})
    }

    loadMeasureData = async () => {
      console.log("in App loadMeasureData()")
      const results = await this.find('/findmeasure')
      console.log(results)
      this.setState({resultList: results})
    }

    /** ベース測定を行います */
    actionBaseMeasurement = async () => {
      this.setState({isBaseMeasuring: "true"})
      this.setState({baseMeasurementId: uuid.v4()})
      const result = await this.modelBle._Measurement()
      this.regist('/base', 'measurement', result, this.state.baseMeasurementId)
      this.setState({isBaseMeasuring: "false"})
      //this.setState({baseId: this.state.baseMeasurementId})
    }

    /** 判定測定を行います */
    actionMeasurement = async () => {
      this.setState({isMeasuring: "true"})
      this.setState({measurementId: uuid.v4()})
      const result = await this.modelBle._Measurement()
      var resultRegist = await this.regist('/measure', 'measurement', result, this.state.measurementId)
      //console.log(resultRegist)
      //alert("label : " + resultRegist[0][1], "測定結果")
      alert("label : " + resultRegist.label, "測定結果")
      this.setState({isMeasuring: "false"})
    }

    /**
     * タグ、コメントを登録します
     * @param {string} comment 
     * @param {string} tag 
     * @param {string} index 
     * @param {string} uuid 
     * @param {boolean} isMeasurement 
     */
    addTag = async (comment, tag, index, uuid, isMeasurement) => {
      const values = {
        'comment': comment,
        'recordResultTag': tag,
        'recordResultIndex': index
      }
      return await this.regist('/addtag', 'tag_data', values, uuid, isMeasurement)
    }

    /**
     * データ登録をします
     * @param {string} api 
     * @param {string} key 
     * @param {string} value 
     * @param {string} uuid 
     * @param {boolean} isMeasurement 
     * @returns {Object}
     */
    regist = async (api, key, value, uuid='', isMeasurement=false) => {
      const items = {
        is_measurement: isMeasurement,
        user_id: this.state.userId,
        base_uuid: this.state.baseId,
        uuid: uuid,
        [key]: value
      }
//      return await this.useRest.post(api, items)
      var result =  await this.useRest.post(api, items)
      //console.log(result)
      return result
    }

    /**
     * データ検索をします
     * @param {string} api
     * @returns {Object}
     */
    find = async (api) => {
      const items = { user_id: this.state.userId }
      const result = await this.useRest.post(api, items)
      return result;
    }


    render () {
      return (
        <div className="App">
          <div>
            <div className="areaHeader">
              <div className="buttonTop" alt="return top">測定アプリ</div>
              <div className="areaUserInfo">
                <div className="areaUserInfoName">
	          name: {this.state.user ? /*this.state.user.username*/ this.state.user.attributes.email.split('@')[0] : ""}
	        </div>
                <div className="areaUserInfoSignOut" onClick={async () => {
                  try {
                    Auth.signOut()
                  } catch (error) {
                    console.log('error:', error)
                  }
                }}>
	          SignOut
	        </div>
	      </div>
            </div>
            <KkTabBar
              topComponent={this}
              curTab={this.state.curTab}
              isPaired={this.state.isPaired}
              isHearterOn={this.state.isHearterOn}
              hearterStatusText={this.state.hearterStatusText}
              isBaseMeasuring={this.state.isBaseMeasuring}
              isMeasuring={this.state.isMeasuring}
            />
            {(this.state.curTab === "measurementTab") && (this.state.isMeasuring === "false") && (this.state.measurementId === null)
              && <KkTabBodyTop
                topComponent={this}
                isPaired={this.state.isPaired}
                isHearterOn={this.state.isHearterOn}
                hearterStatusText={this.state.hearterStatusText}
                isBaseMeasuring={this.state.isBaseMeasuring}
                isMeasuring={this.state.isMeasuring}
                hearterElapsed={this.state.hearterElapsed}
                baseId={this.state.baseId}
                baseIdText={this.state.baseIdText}
              />}
            {(this.state.curTab === "resultlistTab") && (this.state.isMeasuring === "false")
              && <KkTabBodyResultList
                topComponent={this}
                resultList={this.state.resultList}
              />}
            {(this.state.curTab === "baseselectTab") && (this.state.isMeasuring === "false")
              && <KkTabBodyBaseSelect
                topComponent={this}
                baseList={this.state.baseList}
                baseId={this.state.baseId}
              />}
            {(this.state.curTab === "measurementTab") && (this.state.measurementId !== null)
              && <KkTabBodyMeasurement
                topComponent={this}
                isPaired={this.state.isPaired}
                measurementId={this.state.measurementId}
                isHearterOn={this.state.isHearterOn}
                hearterStatusText={this.state.hearterStatusText}
                isBaseMeasuring={this.state.isBaseMeasuring}
                isMeasuring={this.state.isMeasuring}
              />}
            {(this.state.curTab === "basemeasurementTab")
              && <KkTabBodyBaseMeasurement
                topComponent={this}
                isPaired={this.state.isPaired}
                isHearterOn={this.state.isHearterOn}
                hearterStatusText={this.state.hearterStatusText}
                isBaseMeasuring={this.state.isBaseMeasuring}
                isMeasuring={this.state.isMeasuring}
                hearterElapsed={this.state.hearterElapsed}
                baseMeasurementId={this.state.baseMeasurementId}
              />}
          </div>
        </div>
      )
    }
}

//export default App;
export default withAuthenticator(App);
