import KkBase from './KkBase';


export default class KkTabBodyResultList extends KkBase {

//	async componentWillMount() {
//		await this.props.topComponent.loadMeasureData()
//	}

  render() {
		const resultList = this.props.resultList
		return (
			<div className="resultList">
				<table>
					<thead>
						<tr>
							<th>臭気</th>
							<th>ラベル</th>
							<th>タグ</th>
							<th>コメント</th>
						</tr>
					</thead>
					<tbody>
						{resultList && resultList.sort(
                                                        (a, b) => {
								// API側のプロパティ名が間違っている => 仕方ない
								//console.log(a.data, b.data)
								//console.log(a.date, b.date)
                                                                if (a.data < b.data) {
                                                                        return 1
                                                                }
                                                                else if (a.data > b.data) {
                                                                        return -1
                                                                }
                                                                return 0
                                                        }
						).map((result, index) => {
//						{resultList.map((result, index) => {
							return (
								<tr key={index}>
									<td>{this.nullIsEmptyStr(result.recordResultIndex)}</td>
									<td>{result.result != null ? this.nullIsEmptyStr(result.result.label) : ""}</td>
									<td>{this.nullIsEmptyStr(result.recordResultTag)}</td>
									<td>{this.nullIsEmptyStr(result.comment)}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		)
	}
}
