const { 
  KUNKUNUUID,
  PAIRINGCHARACTERISTICUUID,
  HEATERCHARACTERISTICUUID,
  SEARVICEUUID, 
  ENVIRONMENTALSENSINGUUID,
  SENSORDATACHARACTERISTICUUID,
  TEMPERTUREUUID,
  HUMIDITYUUID
} = require('./KkBleConfig');

class KkModelBle {
    constructor(topComponent) {
      this.bluetoothDevice = null;
      this.topComponent = topComponent
      this.baseId = null
      this.baseMeasurementId = null
      this.measurementId = null
      this.measurementTimer = null
      this.measurementCountMax = 180
      this.measurementCount = 0
      this.sensors = []
      this.tempertures = []
      this.humids = []
      this.measurementsData = ''
    }

    /**
     * デバイスとの接続をします
     * @param {string} serviceUuid 
     * @param {string} characteristicUuid 
     */
    connect = async (serviceUuid, characteristicUuid) => {
      try{
        const device = await this.bluetoothDevice.gatt.connect()
        const service =  await device.getPrimaryService(serviceUuid)
        return await service.getCharacteristic(characteristicUuid)
      } catch (e) {
        return
      }
    }

    readValue = async (serviceUuid, characteristicUuid) => {
        const result = await this.connect(serviceUuid, characteristicUuid)
        return result.readValue()
    }

    writeValue = async (serviceUuid, characteristicUuid, data) => {
      const characteristic = await this.connect(serviceUuid, characteristicUuid)
      return characteristic.writeValue(data)
    }

    /**
     * デバイスにメッセージを送信します
     * @param {string} serviceUuid 
     * @param {string} characteristicUuid 
     * @param {byte} data 
     */
    scan = async (serviceUuid, characteristicUuid, data) => {
      if (this.bluetoothDevice) return this.writeValue(serviceUuid, characteristicUuid, data)
      
      const options = {
        filters: [{services: [SEARVICEUUID] }],
        optionalServices: [ENVIRONMENTALSENSINGUUID, serviceUuid]
      }
      try{
        const device = await navigator.bluetooth.requestDevice(options)
        this.bluetoothDevice = device
        return this.writeValue(serviceUuid, characteristicUuid, data)
      }catch (e) {
        return
      }
    }

    /** デバイスとのペアリングをします */
    pairing = async () => {
      await this.hearterOn()
      if (!this.bluetoothDevice) return;
      
      const status = await this.getHearterStatus()
      if (status !== 2) return;
      
      const data = new TextEncoder().encode('kkd01')
      return await this.scan(KUNKUNUUID, PAIRINGCHARACTERISTICUUID, data)
    }

    /** デバイスのヒーターをONにします */
    hearterOn = async () => {
        await this.scan(KUNKUNUUID, HEATERCHARACTERISTICUUID, Uint8Array.from([0x01]))
    }

    /** デバイスのステータスを取得します */
    getHearterStatus = async () => {
        const result = await this.readValue(KUNKUNUUID, HEATERCHARACTERISTICUUID)
        console.log(new DataView(result.buffer).getUint8(0))
        return new DataView(result.buffer).getUint8(0)
    }

    /** 180秒間のセンサーのデータ取得を行います */
    _Measurement = () => {
      return new Promise((resolve) => {
        this.measurementCount = this.measurementCountMax
        this.topComponent.setState({measurementCount: this.measurementCount})
        this.measurementTimer = setInterval(() => {
          this.measurementCount--
          this.topComponent.setState({measurementCount: this.measurementCount})
          this.getValueSensor()
          if (this.measurementCount <= 0) {
            resolve(this.measurementsData)
            clearInterval(this.measurementTimer)
          }
        }, 1000)
      })
    }

    getThermohygrometerData = async (serviceUuid, characteristicUuid) => {
      const data = await this.readValue(serviceUuid, characteristicUuid)
      const dataValue = new DataView(data.buffer)
      return Number(dataValue.getUint16(0, true).toString(10)) / 100;
    }

    convertSensorData = (data) => {
      const value = new DataView(data.buffer)
      const s1 = value.getUint16(0, true).toString(10)
      const s3 = value.getUint16(2, true).toString(10)
      const s4 = value.getUint16(4, true).toString(10)
      const s6 = value.getUint16(6, true).toString(10)
      return `${s1},${s3},${s4},${s6}`
    }

    /** センサーからデータを取得します */
    getValueSensor = async () => {
      const allSensorData = await this.readValue(KUNKUNUUID, SENSORDATACHARACTERISTICUUID)
      const sensor = await this.convertSensorData(allSensorData)
      const temperture = await this.getThermohygrometerData(ENVIRONMENTALSENSINGUUID, TEMPERTUREUUID)
      const humidity = await this.getThermohygrometerData(ENVIRONMENTALSENSINGUUID, HUMIDITYUUID)
      
      if (i => 1) this.measurementsData += `\r\n`;
      this.measurementsData += `${sensor},${temperture},${humidity}`;
    }
}

export default KkModelBle;
